import React from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import styles from './CrossLinksBlock.module.scss';
import CrossLinks from '../CrossLinks';

const CrossLinksBlock = (crossLinks) => (
    <div className={styles['CrossLinksBlock']}>
        <CrossLinks {...crossLinks} />
    </div>
);

CrossLinksBlock.propTypes = {};

CrossLinksBlock.defaultProps = {};

export default CrossLinksBlock;
