import React, { PureComponent } from 'react';

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import styles from './CategoryPage.module.scss';
import Hero from '../../components/Hero';
import SubNavigation from '../../components/SubNavigation';
import StreamField from '../../components/StreamField';
import CrossLinksBlock from '../../components/CrossLinksBlock';

class CategoryPage extends PureComponent {
    state = {};

    static defaultProps = {
        header: null,
    };

    static propTypes = {
        contentBlocks: PropTypes.array,
    };

    render() {
        const { hero, subNavigation, contentBlocks } = this.props;

        // Check if empty menu page, and route nav links to crossLink buttons instead
        const isSubMenuPage =
            !contentBlocks?.length && Boolean(subNavigation.children.length);
        const links =
            isSubMenuPage &&
            subNavigation.children.map((item) => ({
                title: item.label,
                href: item.href,
            }));

        return (
            <div className={styles['CategoryPage']}>
                <Hero {...hero} />

                {!!subNavigation.children.length && !isSubMenuPage && (
                    <SubNavigation {...subNavigation} label={null} />
                )}

                {isSubMenuPage && (
                    <div className={styles['CategoryPage__CrossLinks']}>
                        <CrossLinksBlock links={links} />
                    </div>
                )}

                {!!contentBlocks?.length && (
                    <div className={styles['CategoryPage__Row']}>
                        {!!contentBlocks && (
                            <StreamField items={contentBlocks} />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default basePageWrap(CategoryPage);
