import React from 'react';

import PropTypes from 'prop-types';
import Quicklink from '../QuickLink';
import styles from './CrossLinks.module.scss';

const CrossLinks = ({ links, title }) => {
    return (
        <div className={styles['CrossLinks']}>
            {title && (
                <h2 className={styles['CrossLinks__Title']}>{title}</h2>
            )}
            {links && (
                <div className={styles['CrossLinks__Links']}>
                    {links.map((link, i) => {
                        return (
                            <Quicklink
                                style={{ marginLeft: '24px' }}
                                key={i}
                                href={link.href}
                                color="pink"
                                title={link.title}
                                icon="arrow"
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

CrossLinks.propTypes = {
    links: PropTypes.array,
    title: PropTypes.string,
};

CrossLinks.defaultProps = {
    links: null,
    title: null,
};

export default CrossLinks;
