import React from 'react';
import Icon from '../Icon';
import { ucFirst } from '../../utils/caseconverters.js';
import PropTypes from 'prop-types';
import styles from './QuickLink.module.scss';

const QuickLink = ({ href, icon, color, title, style }) => {
    return (
        <div className={styles['QuickLink']} style={style} tabIndex="0">
            <a
                className={
                    styles['QuickLink__Link'] +
                    ' ' +
                    styles['QuickLink__Link--' + ucFirst(color)]
                }
                href={href}>
                {title && (
                    <span className={styles['QuickLink__Title']}>{title}</span>
                )}
                <span className={styles['QuickLink__Icon']}>
                    <Icon color="white" type={icon} size="large" />
                </span>
            </a>
        </div>
    );
};
QuickLink.propTypes = {
    style: PropTypes.object,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string,
    color: PropTypes.oneOf(['black', 'pink']),
    title: PropTypes.string,
};

QuickLink.defaultProps = {
    style: {},
    url: '/',
    icon: 'arrow',
    color: 'black',
    title: 'Link',
};

export default QuickLink;
